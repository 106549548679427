import React, {useState} from 'react';
import {motion, useAnimation} from 'framer-motion'

//Helpers
import {imgFadeLeft, imgFadeRight, imgFadeUp} from '../../helpers/animationVariants'

//Regex
const imgAltRegex = /.*src="([^"]*)".*alt="([^"]*)".*/


function AIIMage(props){
    //State
    const [imageHeight, setImageHeight] = useState();
    const [imageWidth, setImageWidth] = useState();

    const getImageDimensions = (event) => {
        setImageHeight(event.target.clientHeight);
        setImageWidth(event.target.clientWidth);
    }

    const animation = useAnimation();

    return(
        <>

            {props.animation ?
                <motion.img
                            variants={props.animation === 'ai-fadeup' ? imgFadeUp : props.animation === 'ai-fadeleft' ? imgFadeLeft : props.animation === 'ai-faderight' ? imgFadeRight : undefined  }
                            animate={animation}
                            transition={{duration: .5}}
                            initial = 'hidden'
                            whileInView = 'visible'
                            width={imageWidth ? imageWidth : '1rem'}
                            height={imageHeight ? imageHeight : '1rem'}
                            loading={props.loading ? props.loading : 'lazy'}
                            className={props.className}
                            src={props.imgData ? props.imgData.replace(imgAltRegex, '$1') : props.imgSrc}
                            alt={props.imgData ? props.imgData.replace(imgAltRegex, '$2') : props.imgAlt}
                            onLoad={getImageDimensions}/>
                :

                <img loading={props.loading ? props.loading : 'lazy'}
                     width={imageWidth ? imageWidth : '1rem'}
                     height={imageHeight ? imageHeight : '1rem'}
                     className={props.className}
                     src={props.imgData ? props.imgData.replace(imgAltRegex, '$1') : props.imgSrc}
                     alt={props.imgData ? props.imgData.replace(imgAltRegex, '$2') : props.imgAlt}
                     onLoad={getImageDimensions}/>
            }
        </>
    )
}

export default AIIMage;