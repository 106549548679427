export function getPageData(url){
    const baseURL = process.env.REACT_APP_DEVELOPMENT_CONTENT + "/wp-json/wp/v2/";
    return fetch(baseURL+url, {
        mode: "cors",
        headers:{
            // 'Content-Encoding': 'gzip',
        }})
        .then(res => res.json())
        .then((result) => {
                return result
            },
            (error) => {
                return error
            });
}

export function getPostData(url){
    const baseURL = process.env.REACT_APP_DEVELOPMENT_CONTENT + "/wp-json/wp/v2/";
    return fetch(baseURL+url, {
        mode: "cors",
        headers:{
            // 'Content-Encoding': 'gzip',
        }
    })
        .then(res => res.json())
        .then( (result) => {
                return result
            },
            (error) => {
                return error
            });
}

export function getSearchResults(searchValue){
    const baseURL = process.env.REACT_APP_DEVELOPMENT_CONTENT + "/wp-json/wp/v2/search";
    return fetch(baseURL+searchValue, {
        mode: "cors",
            headers:{
                // 'Content-Encoding': 'gzip',
            }
    }
    )
        .then(res => res.json())
        .then( (result) => {
                return result
            },
            (error) => {
                return error
            });
}