import React, {useEffect, useState, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {NavHashLink} from "react-router-hash-link";
//Importing Components
import AILink from "../abstract/link";
import AISearch from './Search';

//Importing Assets
import logo from '../../assets/images/alumo-logo.svg';
import AIButton from "../button/button";
import AIIMage from "../abstract/Image";

function Navigation() {
    //Location
    let location = useLocation()

    //Refs
    const parentLink = useRef(null)

    //Lifecycle
    useEffect(()=>{
        window.addEventListener('scroll', handleScroll)
        setActivePage(window.location.pathname)
    }, [location])

    useEffect(()=>{
        if(location){
            setOpenNav(false)
        }
    },[location])

    //State
    const [scrolled, setScrolled]       = useState(false);
    const [openNav, setOpenNav]         = useState(false);
    const [activePage, setActivePage]   = useState()
    //Functions
    function handleScroll(){
        if(window.pageYOffset > 20 ){
            setScrolled(true)
        }
        else{
            setScrolled(false)
        }
    }

    //Functions

    function handleOpenNav(){
        setOpenNav(!openNav)
    }

    return (
        // <header className={`ai-header-main ${scrolled ? 'scrolled' : ''} ${openNav ? 'ai-nav-open' : 'ai-nav-closed'} ${location.pathname === '/careers' ? 'ai-dark-background' : ''}`}>
        <header className={`ai-header-main ${scrolled ? 'scrolled' : ''} ${openNav ? 'ai-nav-open' : 'ai-nav-closed'}`}>
            <div className={'container'}>
                <div className={'row g-0'}>
                    <div className={'col-md-2 ai-brand'}>
                            <div className={''}>
                                <Link to={'/'}>
                                    <AIIMage imgSrc={logo} imgAlt={'Alumo Logo'} height={'82'} width={'216'}/>
                                </Link>
                        </div>
                    </div>
                    <div className="col-md-9 offset-md-1 d-flex justify-content-end">
                        <nav className={'ai-navigation'}>
                            <ul>
                                <li className={'ai-header-link'} ref={parentLink}>
                                    {<AILink text={'For my Home'} url={'/residential'} type={'ai-dropdown-link'} className={activePage === '/residential' ? 'ai-active-nav-link' : undefined}  />}
                                    <ul className={'ai-dropdown-menu'}>
                                        <li className={'ai-link' + activePage === '/residential' ? 'ai-active-nav-link' : undefined}>
                                            <Link to={"/residential"} className={activePage === '/residential' ? 'ai-active-nav-link' : undefined}>About</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <NavHashLink to={"/solar-system-for-house#brands"} className={activePage === '/what-we-do' ? 'ai-active-nav-link' : undefined}>Brands We Use</NavHashLink>
                                        </li>
                                        <li className={'ai-link'}>
                                            <Link to={"/customer-experiences"} className={activePage === '/customer-experiences' ? 'ai-active-nav-link' : undefined} state={{selectedCat: "residential"}}>Work We've Done</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <NavHashLink to={"/residential#faq"} className={activePage === '/residential' ? 'ai-active-nav-link' : undefined}>FAQ's</NavHashLink>
                                        </li>
                                    </ul>
                                </li>
                                <li className={'ai-header-link'} ref={parentLink}>
                                    {<AILink text={'For my Business'} url={'/commercial'} type={'ai-dropdown-link'} className={activePage === '/commercial' ? 'ai-active-nav-link' : undefined}  />}
                                    <ul className={'ai-dropdown-menu'}>
                                        <li className={'ai-link' + activePage === '/commercial' ? 'ai-active-nav-link' : undefined}>
                                            <Link to={"/commercial"} className={activePage === '/commercial' ? 'ai-active-nav-link' : undefined}>About</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <NavHashLink to={"/commercial#customers"} className={activePage === '/commercial' ? 'ai-active-nav-link' : undefined}>Customers Who Trust Us</NavHashLink>
                                        </li>
                                        <li className={'ai-link'}>
                                            <Link to={"/customer-experiences"} className={activePage === '/customer-experiences' ? 'ai-active-nav-link' : undefined} state={{selectedCat: "commercial"}}>Work We've Done</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <NavHashLink to={"/commercial#faq"} className={activePage === '/commercial' ? 'ai-active-nav-link' : undefined}>FAQ's</NavHashLink>
                                        </li>
                                    </ul>
                                </li>

                                <li className={'ai-header-link'} ref={parentLink}>
                                    {<AILink text={'Why Alumo'} url={'/why-alumo'} type={'ai-dropdown-link'} className={activePage === '/why-alumo' ? 'ai-active-nav-link' : undefined}  />}
                                    <ul className={'ai-dropdown-menu ai-grandchild-dropdown-menu ai-split-list-dropdown'}>
                                        <li className={'ai-link' + activePage === '/abou-us' ? 'ai-active-nav-link' : undefined}>
                                            <Link to={"/about-us"} className={activePage === '/about-us' ? 'ai-active-nav-link' : undefined}>About Us</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <Link to={"/home-solar-battery-backup"} className={activePage === '/home-solar-battery-backup' ? 'ai-active-nav-link' : undefined}>Solar & Battery Solutions</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <Link to={"/solar-system-for-house"} className={activePage === '/what-we-do' ? 'ai-active-nav-link' : undefined}>What We Do</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <Link to={"/backup-solutions"} className={activePage === '/backup-solutions' ? 'ai-active-nav-link' : undefined}>Backup Solutions</Link>
                                        </li>
                                        <li className={'ai-link'}>
                                            <NavHashLink to={"/solar-system-for-house#how-we-do-energy"} className={activePage === '/solar-system-for-house' ? 'ai-active-nav-link' : undefined}>How We Do Energy</NavHashLink>
                                        </li>
                                        <li className={'ai-link ai-dropdown-link'} ref={parentLink}>
                                            {<AILink linkAnimation={true}  text={'Guides'} url={'/go-solar'} type={'ai-dropdown-link'} className={activePage === '/go-solar' ? 'ai-active-nav-link' : undefined}/>}
                                            {/* <Link to={"/solar-backup"} className={activePage === '/solar-backup' ? 'ai-active-nav-link ai-dropdown-link-link' : 'ai-dropdown-link-link'}>Solar Backup</Link> */}
                                            <ul className={'ai-dropdown-menu ai-link-dropdown-menu ai-grandchild-dropdown-menu'}>
                                                <li className={'ai-link ai-dropdown-link'}>
                                                    <Link to={"/go-solar"} className={activePage === '/go-solar' ? 'ai-active-nav-link' : undefined}>Go Solar</Link>
                                                </li>
                                                <li className={'ai-link ai-dropdown-link'}>
                                                    <Link  to={"/off-grid-solar-system"} className={activePage === '/off-grid-solar-system' ? 'ai-active-nav-link' : undefined}>Off Grid Solar System</Link>
                                                </li>
                                                <li className={'ai-link ai-dropdown-link'}>
                                                    {<AILink linkAnimation={true}  text={'Solar Solutions For Home'} url={'/solar-solutions-for-home-why-solar-energy-is-the-way-to-go'} type={'ai-dropdown-link'} className={activePage === '/solar-solutions-for-home-why-solar-energy-is-the-way-to-go' ? 'ai-active-nav-link' : undefined}/>}
                                                    <ul className={'ai-dropdown-menu ai-link-dropdown-menu'}>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link  to={"/solar-power-system-for-home"} className={activePage === '/solar-power-system-for-home' ? 'ai-active-nav-link' : undefined}>Solar Power System For Home</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className={'ai-link ai-dropdown-link'}>
                                                    {<AILink linkAnimation={true}  text={'Solar Backup'} url={'/solar-backup'} type={'ai-dropdown-link'} className={activePage === '/solar-backup' ? 'ai-active-nav-link' : undefined}/>}
                                                    <ul className={'ai-dropdown-menu ai-link-dropdown-menu'}>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link to={"/solar-system-with-battery"} className={activePage === '/solar-system-with-battery' ? 'ai-active-nav-link' : undefined}>Solar System With Battery</Link>
                                                        </li>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link to={"/solar-panel-battery-get-switched-on-with-alumo"} className={activePage === '/solar-panel-battery-get-switched-on-with-alumo' ? 'ai-active-nav-link' : undefined}>Solar panel battery details</Link>
                                                        </li>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link to={"/solar-battery-backup-systems-beat-the-blackouts-with-alumo"} className={activePage === '/solar-battery-backup-systems-beat-the-blackouts-with-alumo' ? 'ai-active-nav-link' : undefined}>Solar battery backup systems</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className={'ai-link ai-dropdown-link'}>
                                                    {<AILink linkAnimation={true}  text={'Solar Rentals'} url={'/solar-rentals'} type={'ai-dropdown-link'} className={activePage === '/solar-rentals' ? 'ai-active-nav-link' : undefined}/>}
                                                    <ul className={'ai-dropdown-menu ai-link-dropdown-menu'}>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link  to={"/rent-to-own-solar-system-everything-you-need-to-know"} className={activePage === '/rent-to-own-solar-system-everything-you-need-to-know' ? 'ai-active-nav-link' : undefined}>Rent to own solar system</Link>
                                                        </li>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link  to={"/rental-solar-reduce-electricity-costs"} className={activePage === '/rental-solar-reduce-electricity-costs' ? 'ai-active-nav-link' : undefined}>Rental Solar: Reduce Electricity Costs</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className={'ai-link ai-dropdown-link'}>
                                                    {<AILink linkAnimation={true}  text={'Solar Systems South Africa'} url={'/solar-systems-south-africa'} type={'ai-dropdown-link'} className={activePage === '/solar-systems-south-africa' ? 'ai-active-nav-link' : undefined}/>}
                                                    <ul className={'ai-dropdown-menu ai-link-dropdown-menu'}>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link to={"/solar-panel-prices"} className={activePage === '/solar-panel-prices' ? 'ai-active-nav-link' : undefined}>Solar Panel Prices</Link>
                                                        </li>
                                                        <li className={'ai-link ai-dropdown-link'}>
                                                            <Link to={"/comprehensive-guide-to-solar"} className={activePage === '/comprehensive-guide-to-solar' ? 'ai-active-nav-link' : undefined}>Solar Installation</Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className={'ai-link'}>
                                            <NavHashLink to={"/why-alumo#benefits-of-alumo"} className={activePage === '/why-alumo' ? 'ai-active-nav-link' : undefined}>Benefits Of Going Alumo</NavHashLink>
                                        </li>
                                    <li className={'ai-link'}>
                                        <Link to={"/articles"} className={activePage === '/articles' ? 'ai-active-nav-link' : undefined}>Blog</Link>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                {<AILink  text={'Shop'} url={'https://alumo.co.za/shop/'} type={'external-link'} className={activePage === '/shop' ? 'ai-active-nav-link' : undefined}/>}
                            </li>
                            <li className={'ai-header-link'} ref={parentLink}>
                                {<AILink  text={'Get in touch'} url={'/contact-us#get-in-touch'} type={'ai-dropdown-link'} className={activePage === '/contact-us#get-in-touch' ? 'ai-active-nav-link' : undefined}/>}
                                <ul className={'ai-dropdown-menu'}>
                                    <li className={'ai-link'}>
                                        <NavHashLink  to={"/book-one-of-solar-solutions-experts"} className={activePage === '/book-one-of-solar-solutions-experts' ? 'ai-active-nav-link' : undefined}>Book a free consultation</NavHashLink>
                                    </li>
                                    <li className={'ai-link'}>
                                        <NavHashLink to={"/start-saving"} className={activePage === '/start-saving' ? 'ai-active-nav-link' : undefined}>Get an instant quote</NavHashLink>
                                    </li>
                                    <li className={'ai-link'}>
                                        <NavHashLink to={"/contact-us"} className={activePage === '/contact-us' ? 'ai-active-nav-link' : undefined}>Contact us</NavHashLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                            <AIButton className={'ai-header-button ai-header-button-mobile'} type={'ai-saving-btn'} url={'/start-saving'}>Get a quote</AIButton>
                        </nav>
                        <div className={'ai-header-right'}>
                            <AISearch/>
                            <AIButton className={'ai-header-button'} type={'ai-saving-btn'} url={'/start-saving'}>Get a quote</AIButton>
                            <div className={'ai-mobile-nav-toggle'} onClick={handleOpenNav}>
                                <div className={'ai-bar'}></div>
                                <div className={'ai-bar'}></div>
                                <div className={'ai-bar'}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </header>
    )
}

export default Navigation;