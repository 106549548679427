import React, {useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {getPageData, getSearchResults} from "../../functions/getPageData";
import {replaceEntity} from "../../helpers/ReplaceEntity";

function AISearchBar(props) {

    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");


      const onChange = (e) => {

            const userInput = e.target.value;

            setInput(e.target.value);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
      };

      const onClick = (e) => {
            setFilteredSuggestions([]);
            setInput(e.target.innerText);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
      };

      const onKeyDown = (e) => {
            setShowSuggestions(true);
            getSearchResults(`?search=${input}`).then((result)=>{
                setFilteredSuggestions(result)
            })
          if(input <= 3 || e.key === 'Backspace' ){
              setFilteredSuggestions([])
          }
      };


      const SuggestionsListComponent = () => {
            return filteredSuggestions.length ? (
                  <ul className="ai-suggestions">
                        {filteredSuggestions.map((suggestion, index) => {
                        let className;
                        if (index === activeSuggestionIndex) {
                              className = "ai-suggestion-active";
                        }
                         function PostURL(suggestionUrl, type){
                            let theUrlArray = suggestion.url.split('/').splice(3);
                            let theUrl = suggestionUrl.split('/').splice(3).join('/')
                             if(theUrl === "home/"){
                                 return '/'
                             } else if(theUrlArray[1] === 'customer-experience'){
                                 theUrlArray.shift()
                                 theUrlArray[0] = theUrlArray[0] + 's'

                                 return theUrlArray.join('/')
                             }else if(type === 'products'){
                                 return `home-solar-solutions/${suggestionUrl.split('/')[5]}`
                             } else if(type === 'post' && type !== 'products'){
                                 return `articles/${suggestionUrl.split('/')[4]}`
                             }
                             else{
                                 return suggestionUrl.split('/').splice(3).join('/')
                             }
                        }
                        return (
                              <li className={className} key={index} onClick={props.onClick}>
                                <Link to={PostURL(suggestion.url, suggestion.subtype)}>{replaceEntity(suggestion.title)}</Link>
                              </li>
                            );
                        })}
                  </ul>
            ) : (
                  <ul className=" ai-suggestions ai-no-suggestions">
                        <li><em>No suggestions found!</em></li>
                  </ul>
            );
      };

    let navigate = useNavigate();
    function handleSearch(){

      if(input.length > 0){
          navigate(`search-results/${input}`);
          props.onClick()
      }
    }
    
      return (
            <div className=" ai-search-wrapper justify-content-between align-items-baseline">

                  <div className="ai-search-bar">
                              <div className='ai-search-bar-form'>
                                          <input
                                                type="text"
                                                onChange={onChange}
                                                onKeyDown={onKeyDown}
                                                value={input}
                                                placeholder="Enter your search here…"
                                          />
                                          <button className={'ai-button'} onClick={()=>handleSearch()}>
                                              Search
                                          </button>
                              </div>
                              <div>
                                    {showSuggestions && input && <SuggestionsListComponent />}
                              </div>

                 </div>
                 
                  <div>
                  <div onClick={props.onClick}  className="ai-modal-close d-flex align-items-center justify-ntent-start" ><i class="ri-close-line"></i> Close</div>

                  </div>
            </div>
    )
}

export default AISearchBar;