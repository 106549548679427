function Popup({setShowPopup, popupImage}){

    function handleOnClick(){
        setShowPopup()
        window.sessionStorage.setItem('showPopup', false)
    }

    return(
        <div className={'ai-eipopup-container'}>
            <div className="ai-eipopup-gif-container">
                <div className={'ai-popup-close'} onClick={handleOnClick}><i className="ri-close-line"></i></div>
                <img src={popupImage} alt={'Alumo Popup'}/>
            </div>
        </div>
    )
}

export default Popup;