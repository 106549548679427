import {motion} from "framer-motion";
import React from "react";
import {parse} from "node-html-parser";

export const imgFadeUp = {
    visible: {
        opacity: 1,
        y: 0
    },
    hidden: {
        opacity: 0,
        y: 100
    },
}

export const imgFadeLeft = {
    visible: {
        opacity: 1,
        x: 0
    },
    hidden: {
        opacity: 0,
        x: -100
    },
}

export const imgFadeRight = {
    visible: {
        opacity: 1,
        x: 0
    },
    hidden: {
        opacity: 0,
        x: 100
    },
}

export const headingAnimation = {
        visible: {
            y: 0,
        },
        hidden: {
            y: 66,
        },
}

export const splitLine = {
    visible:{
        y: 0,
    },
    hidden: {
        y: '42px'
    },
}

export const homePageBanner = {
    visible:{
        y: 0,
    },
    hidden: {
        y: '120px'
    },
}



//Animation Functions
//Animated section headings
export const animateTextFunction = (text, offset) => {
    //Add Text to an array
    const textArray = text.split(' ');

    //Check for opening and closing em tags in array, and wrap elements between in em
    //so they wont get cut in next loop
    if(textArray.find(element => element.includes('</em>')) && textArray.find(element => element.includes('<em>'))){
        let opening = false;
        let whileIndex = textArray.length - 1;
        while(whileIndex >= 0 && opening === false){
            if(textArray[whileIndex].includes('<em>')){
                textArray[whileIndex] = textArray[whileIndex] + '</em>';
                opening = true;
            } else if(textArray[whileIndex].includes('</em>')){
                textArray[whileIndex] = '<em>' + textArray[whileIndex];
            } else {
                textArray[whileIndex] = `<em>${textArray[whileIndex]}</em>`
            }

            whileIndex = whileIndex - 1
        }
    }


    //Initialize New Array
    const animatedText = [];

    //Adds Spaces
    textArray.forEach((item, index)=>{
        animatedText.push(item)

        if(index !== textArray.length - 1){
            animatedText.push("\u00A0")
        }


    })

    // Returns New Animated Text

    return(
        animatedText.map((word, i)=>{
            return(
                <span key={i} className={'ai-word-container'}>
                    <motion.span style={{display: 'inline-block'}}
                                 variants={splitLine}
                                 initial={offset ? {y: offset} : 'hidden'}
                                 whileInView={'visible'}
                                 transition={{duration: .3, delay: i * .030}} dangerouslySetInnerHTML={{__html: word}}>
                    </motion.span>
                </span>
            )
        })
    )
}

//Home page text animation
//Gets page Title For animation loop
export const homePageTextAnimation = (text) => {
    text = parse(text).firstChild.innerHTML;

    //Add Text to an array
    const textArray = text.split(' ');

    //Initialize New Array
    const animatedText = [];

    //Adds Spaces
    textArray.forEach((item,index)=>{
        animatedText.push(item)

        if(index < textArray.length - 1){
            animatedText.push("\u00A0")
        }
    })

    // wrap em's
    let gradientText = [];
    let indexOfFirstEm= 0;
    let indexOfLastEm = 0;
    let span = '';
    animatedText.forEach((item, index)=>{
        if(item.includes('</em>')){
            let whileIndex = index + 1;
            while(whileIndex >= 0){

                if(animatedText[whileIndex].includes('</em>') && !animatedText[whileIndex].includes('<em>')){
                    animatedText[whileIndex] = `<em>${animatedText[whileIndex]}`
                    gradientText.push(animatedText[whileIndex]);
                    indexOfLastEm = whileIndex
                }else if(animatedText[whileIndex].includes('<em>') && !animatedText[whileIndex].includes('</em>')){
                    animatedText[whileIndex] = `${animatedText[whileIndex]}</em>`;
                    gradientText.push(animatedText[whileIndex]);
                    indexOfFirstEm = whileIndex;
                    whileIndex = 0;
                }
                whileIndex = whileIndex -1
            }
        }
    })
    span = `<span class="ai-gradient-text">${gradientText.reverse().join(' ')}</span>`
    animatedText.splice(indexOfFirstEm, indexOfLastEm+1, span);

    // Returns New Animated Text
    return(
        animatedText.map((word, i)=>{
            return(
                <span key={i} className={'ai-word-container'}>
                        <motion.span style={{display: 'inline-block'}}
                                     variants={homePageBanner}
                                     initial={'hidden'}
                                     animate={{y: 0}}
                                     transition={{duration: .5, delay: i * .040}} dangerouslySetInnerHTML={{__html: word}}>
                        </motion.span>
                    </span>
            )
        })
    )
}